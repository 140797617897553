<template>
	<v-container class="flex-column flex-nowrap align-self-start">
		<v-subheader class="title">Vad vill du boka?</v-subheader>

		<apollo-query
			:query="issueTypeQuery"
			:update="data => data.issueTypes[0]"
			:variables="{ input: { view: 'book' } }"
		>
			<template v-slot="{ result: { data: issueType } }">
				<apollo-query
					:query="companyQuery"
					:update="data => data.company.services"
				>
					<template v-slot="{ result: { data: services } }">
						<template v-if="services">
							<v-row no-gutters class="mb-2">
								<v-col :cols="12" class="pa-1">
									<v-card
										@click="openIssueCreation(issueType, services[0])"
										:img="services[0].imageUrl"
										class="booking-card"
										color="primary"
										elevation="3"
										dark
									>
										<v-card-title class="booking-text">
											<div class="booking-background">
												{{ services[0].name }}
											</div>
										</v-card-title>
									</v-card>
								</v-col>

								<template v-if="services.length % 2">
									<v-col
										:cols="6"
										v-for="(n, index) in services.length - 1"
										:key="index"
										class="pa-1"
									>
										<v-card
											@click="openIssueCreation(issueType, services[n])"
											:img="services[n].imageUrl"
											class="booking-card"
											color="primary"
											elevation="3"
											dark
										>
											<v-card-title class="booking-text">
												<div class="booking-background">
													{{ services[n].name }}
												</div>
											</v-card-title>
										</v-card>
									</v-col>
								</template>
								<template v-else>
									<v-col
										:cols="6"
										v-for="n in services.length - 2"
										:key="services[n].id"
										class="pa-1"
									>
										<v-card
											@click="openIssueCreation(issueType, services[n])"
											:img="services[n].imageUrl"
											class="booking-card"
											color="primary"
											dark
										>
											<v-card-title class="booking-text">
												<div class="booking-background">
													{{ services[n].name }}
												</div>
											</v-card-title>
										</v-card>
									</v-col>

									<v-col :cols="12" class="pa-1">
										<v-card
											@click="
												openIssueCreation(issueType, services.slice(-1)[0])
											"
											:img="services.slice(-1)[0].imageUrl"
											class="booking-card"
											color="primary"
											dark
										>
											<v-card-title class="booking-text">
												<div class="booking-background">
													{{ services.slice(-1)[0].name }}
												</div>
											</v-card-title>
										</v-card>
									</v-col>
								</template>
							</v-row>
						</template>
					</template>
				</apollo-query>
			</template>
		</apollo-query>

		<v-dialog v-model="overlay" max-width="800px">
			<a-create-issue-card
				:issueType="chosenIssueType"
				@close="overlay = false"
			/>
		</v-dialog>
	</v-container>
</template>

<script>
import ACreateIssueCard from '@/components/CreateIssueCard';
import { ISSUE_CREATION_TYPES, COMPANY } from '@/graphql';

export default {
	components: {
		ACreateIssueCard,
	},
	apollo: {
		issueType: {
			query: ISSUE_CREATION_TYPES,
			variables: {
				input: {
					view: 'book',
				},
			},
			update: data => data.issueTypes[0],
		},
	},
	data: () => ({
		issueTypeQuery: ISSUE_CREATION_TYPES,
		companyQuery: COMPANY,
		chosenIssueType: {},
		overlay: false,
	}),
	methods: {
		openIssueCreation(issueType, service) {
			this.chosenIssueType = {
				...service,
				...issueType,
				subject: issueType.subject + ' - ' + service.name,
			};
			this.overlay = true;
		},
	},
	metaInfo: {
		title: 'Boka',
	},
};
</script>

<style>
.booking-card {
	min-height: 150px;
	opacity: 0.8;
}
.booking-text {
	font-size: 16px;
	font-size: 5vw;
}

.booking-background {
	padding: 0 4px;
	border-radius: 4px;
	background-color: white;
	color: black;
}

@media screen and (min-width: 500px) {
	.booking-text {
		font-size: 26px;
	}
}
</style>
